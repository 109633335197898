$accent-color: #f9423a;
$gray-color-light: #f7f7f7;
$text-color: #63666a;

a {
  color: $accent-color;
}

/***************************************
 * フッター
 ***************************************/
.footer {
  .copyright::before {
    content: '© TABIKOBO Co. Ltd.';
  }
}

.dashboard .footer {
  background: #fff;
  color: $text-color;
  padding: 16px 32px;

  .navigation-links a {
    color: $text-color;
  }

  .navigation-actions a {
    color: $accent-color;
  }

  .logo-img2 {
    background: none;
  }

  .copyright {
    color: $text-color;
  }
}

/***************************************
 * ログイン前Top
 ***************************************/
.biz-pc__wrapper {
  > * {
    display: none;
  }

  .biz-pc__main-top,
  .biz-sp__main-top {
    display: block;
    height: 100vh;
  }

  .biz-pc__apply-area,
  .biz-sp__apply-area {
    display: none;
  }
}

/***************************************
 * ログイン後Top
 ***************************************/
.logo-img {
  background-image: url('/images/logo/tabikobo/logo.png');
  width: 120px;
}

.dashboard__pc header {
  background: #fff;
  border: none;
}

.trips-button-box {
  .at-icon {
    color: $text-color;
  }

  &.selected {
    border-bottom-color: $accent-color;

    .at-icon {
      color: $accent-color;
    }

    .trips-button {
      color: $accent-color;
    }
  }

  .trips-button {
    color: $text-color;
    transition: opacity .3s;

    &:hover,
    &:focus {
      color: $text-color;
    }

    &:hover {
      opacity: .8;
    }
  }
}

.simple-selector__link {
  color: $text-color;

  &.selected {
    color: $accent-color;
  }
}

.search__block__body__fields-wrapper__field__label {
  color: $accent-color;
}

.search__block__header__left__icon {
  background: $accent-color;
}

.search__add-block {
  color: $accent-color;
}

input[type="submit"].big-button {
  background: $accent-color;
  transition: opacity .3s;

  &:hover {
    background: $accent-color;
    opacity: .8;
  }

  &:focus {
    background: $accent-color;
  }
}

.dashboard__search-area {
  flex-grow: 1;

  &:after {
    background: url('/images/biz_lp/tabikobo/cover.png') center / cover no-repeat;
  }
}

.dashboard__help-area,
.dashboard__inquiry-area {
  display: none;
}

@media screen and (max-width: 430px) {
  .dashboard__search-area {
    background: $gray-color-light;
  }
}

/***************************************
 * サインイン
 ***************************************/
.logo-img2 {
  background-image: url('/images/logo/tabikobo/logo2.png');
  width: 120px;
}

.user-signin__body {
  .logo-container {
    background-color: $accent-color;

    .logo-img2 {
      height: 58px;
    }
  }

  button {
    background-color: $accent-color;
    outline: none;
    transition: opacity .3s;

    &:hover {
      opacity: .8;
    }
  }
}

.user-signin {
  .footer {
    .navigation-actions a {
      color: $accent-color;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    .navigation-actions {
      padding: 0;

      a {
        color: #fff !important;
        background-color: $accent-color;

        &:hover {
          background-color: $accent-color;
        }
      }
    }

    .logo-img2 {
      display: none;
    }
  }

  .dashboard .footer .copyright-container {
    background: none;
  }
}